<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
            <!-- <react-table
                ref="listTable"
                :table-data="tableData"
                :total-items="totalItems"
                :showTotal="showTotalFun"
                :actions-type="actionsType"
                :columns="columns"
                :hide-row-selection="true"
                :scroll="{x: 1200}"
                :is-full="true"
                :current-page="pageNumber"
                @emitRefreshData="clearFilterAndRefresh"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            > -->
            <ele-table
                :columns="columns"
                :table-data="tableData"
                :total-items="totalItems"
                :is-full="true"
                :actionsType="actionsType"
                :current-page="pageNumber"
                :hide-row-selection="true"
                @emitRefreshData="clearFilterAndRefresh"
                @emitOnPageChange="updatePageChange"
                @emitShowSizeChange="updateShowSizeChange"
            >
                <a-row :gutter="8" type="flex" align="center">
                    <a-col class="gutter-row" :span="6">
                        <a-input v-model.trim="SearchDto.sceneName"
                                :placeholder="l('TrainName')" />
                    </a-col>
                    <a-col class="gutter-row" :span="4">
                        <a-button type="primary" @click="search" v-if="isGranted('Pages.TrainPermissions.Query')">
                            {{ l("Search") }}
                        </a-button>
                        <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                    </a-col>

                    <a-col span="14" align="right">
                        <a-button type="primary" @click="createOrEdit" v-if="isGranted('Pages.TrainPermissions.Create')">{{l("Create")}}</a-button>
                    </a-col>
                </a-row>
            </ele-table>

            <a-table :data-source="tableData" :pagination="pagination" @change="tableChange" :scroll="{x: 1500}" style="margin-top: 20px;" v-if="false">
                <!-- <a-table-column-group> -->
                    <a-table-column :key="1" align="center" :title="l('TrainHeaderSiteName')" data-index="name" width="150px" fixed="left" />
                    <a-table-column :key="2" align="center" :title="l('Introduction')" data-index="description" width="250px" />
                    <a-table-column :key="3" align="center" :title="l('TrainHeaderEffectiveTime')" data-index="id"  width="300px">
                        <template slot-scope="text, scope">
                            <p>{{l("TrainTime")}}：{{scope.trainTime}}</p>
                            <p>{{l("EndTime")}}：{{scope.endTrainTime}}</p>
                            <p>{{l("ExamTime")}}：{{scope.testTime}}</p>
                            <p>{{l("EndTime")}}：{{scope.endTestTime}}</p>
                        </template>
                    </a-table-column>
                    <a-table-column :key="4" align="center" :title="l('TrainHeaderNotify')" data-index="id" width="100px">
                        <template slot-scope="text, scope">
                            <a-tooltip placement="topRight">
                                <template slot="title">
                                    <p>{{l("StudyNotifyUrl")}}：{{scope.studyNotifyUrl}}</p>
                                    <p>{{l("ExamNotifyUrl")}}：{{scope.testNotifyUrl}}</p>
                                    <p>{{l("CheckUri")}}：{{scope.verificationUrl}}</p>
                                </template>
                                <a-tag color="blue">{{l("Detail")}}</a-tag>
                            </a-tooltip>
                        </template>
                    </a-table-column>
                    <a-table-column :key="5" align="center" :title="l('TrainHeaderTemplate')" data-index="template" width="200px">
                        <template slot-scope="text, scope">
                            <p>{{l("ExamLength")}}：{{scope.testLengthTime}}</p>
                            <p style="margin-left: 10px;"><a-tag color="cyan">{{l("SingerTitle")}}</a-tag>&nbsp;{{scope.singleText}}</p>
                            <p style="margin-left: 10px;"><a-tag color="pink">{{l("MultipleTitle")}}</a-tag>&nbsp;{{scope.multText}}</p>
                        </template>
                    </a-table-column>
                    <a-table-column :key="6" align="center" :title="l('Status')" data-index="status">
                        <template slot-scope="text, scope">
                            <a-tag :color="scope.status == 0 ? 'red' : 'blue'">{{scope.status == 0 ? l("Invalid") : l("Effective")}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="7" align="center" :title="l('TrainHeaderIsCheck')" data-index="isCheck">
                        <template slot-scope="text, scope">
                            <a-tag :color="scope.isCheck == 0 ? 'red' : 'blue'">{{scope.isCheck == true ? l("NeedClass") : l("NotNeedClass")}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="8" align="center" :title="l('OrderDate')" data-index="createTime" width="200px" />
                    <a-table-column :key="9" align="center" :title="l('Action')" data-index="id" width="120px" fixed="right">
                        <template slot-scope="text, scope">
                            <a class="tbl-edit" v-for="item in ActionList" :key="item.key" >
                                <div v-if="item.granted" @click="emitItem(item, scope)">
                                    <span v-if="item.key != 'disabled'"><a-icon :type="item.icon" />{{item.name}}&nbsp;&nbsp;</span>
                                    <span v-if="item.key == 'disabled'"><a-icon :type="item.icon" />{{scope.status == 0 ? l("Enable") : l("Disabled")}}&nbsp;&nbsp;</span>
                                </div>
                                
                            </a>
                        </template>
                    </a-table-column>
                <!-- </a-table-column-group> -->
            </a-table>

    </a-spin>
</template>
<script>
    import { AppComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import axios from "axios";
    import CreateOrUpdate from "./addoredit.vue";
    import StudyList from "./studylist.vue";
    import moment from "moment";
    import EleTable from "@/components/ele-table";

    export default {
        mixins: [AppComponentBase],
        name: "projectmodule",
        components: { EleTable },
        data: function () {
            return {
                spinning: false,
                SearchDto: {
                    sceneName: ""
                },
                selectedRowKeys: [],
                ActionList: [],
                pagination: { current: 1, pageSize: 5, total: 1 },
                pageSizeOptions: ["5","10", "20", "30", "40"],
                tableData: [],//{ id: '123', name: '测试', description: '测试好玩吗', trainTime: '2021-2-2 17:00', endTrainTime: '2021-2-3 17:00', testTime: '2021-2-5 17:00', endTestTime: '2021-2-5 18:00', status: 0, isCheck: true, createTime: '2021-2-1 17:00' }
                // 选择多少项
                selectedRowKeys: [],
                selectedRows: [],
                tableData: [],
                columns: [],
                // 总数
                totalItems: 0,
                // 当前页码
                pageNumber: 1,
                // 共多少页
                totalPages: 1,
                // 条数显示范围
                pagerange: [1, 1],
                // 显示条数
                pageSizeOptions: ["10", "20", "30", "40"],
                request: { sorting: "", maxResultCount: 50, skipCount: 0 }, //sorting {排序项 ASC/DESC}
                // list-table btns显示参数
                actionsType: {},
            };
        },
        created() {
            this.getData();
            this.initActionsType();
            this.initColumns()
        },
        methods: {
            initColumns() {
                let _this = this
                this.columns = [
                    {
                        title: this.l("TrainHeaderSiteName"),
                        dataIndex: "name",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "name" },
                    },
                    {
                        title: this.l("培训时间"),
                        dataIndex: "trainTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "trainTime" },
                    },
                    {
                        title: this.l("结束时间"),
                        dataIndex: "endTrainTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "endTrainTime" },
                    },
                    {
                        title: this.l("考试时间"),
                        dataIndex: "testTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "testTime" },
                    },

                    {
                        title: this.l("通知类url"),
                        dataIndex: "urls",
                        sorter: false,
                        align: "center",
                        type: 'popover',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tooltip", {
                                domProps: {
                                    innerHTML: `<a>查看</a>`,
                                },
                                attrs: { title: `
                                    ${_this.l("StudyNotifyUrl")}: ${record.studyNotifyUrl}, 
                                    ${_this.l("ExamNotifyUrl")}: ${record.testNotifyUrl},
                                    ${_this.l("CheckUri")}: ${record.verificationUrl}
                                `},
                                on: {
                                    click: function () {},
                                },
                            });
                            let obj = {
                                children: child,
                                attrs: {},
                                on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "id" },
                    },
                    {
                        title: this.l("TrainHeaderTemplate"),
                        dataIndex: "template",
                        sorter: false,
                        align: "center",
                        type: 'popover',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tooltip", {
                            domProps: {
                                innerHTML: `<a>查看</a>`,
                            },
                            attrs: {title: `
                                ${_this.l("ExamLength")}：${record.testLengthTime},
                                ${_this.l("SingerTitle")}：${record.singleText},
                                ${_this.l("MultipleTitle")}：${record.multText}
                            `},
                            on: {
                                click: function () {},
                            },
                            });
                            let obj = {
                            children: child,
                            attrs: {},
                            on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "template" },
                    },
                    
                    {
                        title: this.l("Status"),
                        dataIndex: "status",
                        sorter: false,
                        align: "center",
                        type: 'tag',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tag", {
                            domProps: {
                                innerHTML: `${record.status == 0 ? _this.l("Invalid") : _this.l("Effective")}`,
                            },
                            attrs: {title: `${record.status == 0 ? _this.l("Invalid") : _this.l("Effective")}`, color: record.status == 0 ? 'red' : 'blue'},
                            on: {
                                click: function () {},
                            },
                            });
                            let obj = {
                            children: child,
                            attrs: {},
                            on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "status" },
                    },
                    {
                        title: this.l("TrainHeaderIsCheck"),
                        dataIndex: "isCheck",
                        sorter: false,
                        align: "center",
                        type: 'tag',
                        customRender: function (text, record, index) {
                            let child = _this.$createElement("a-tag", {
                            domProps: {
                                innerHTML: `${record.isCheck == true ? _this.l("NeedClass") : _this.l("NotNeedClass")}`,
                            },
                            attrs: {title: `${record.isCheck == 0 ? _this.l("NeedClass") : _this.l("NotNeedClass")}`, color: record.isCheck == true ? 'red' : 'blue'},
                            on: {
                                click: function () {},
                            },
                            });
                            let obj = {
                            children: child,
                            attrs: {},
                            on: {},
                            };
                            return obj;
                        },
                        scopedSlots: { customRender: "isCheck" },
                    },
                    {
                        title: this.l("OrderDate"),
                        dataIndex: "createTime",
                        sorter: false,
                        align: "center",
                        scopedSlots: { customRender: "createTime" },
                    },
                    {
                        title: this.l("Actions"),
                        dataIndex: "actions",
                        sorter: false,
                        align: "center",
                        fixed: "right",
                        width: 100,
                        scopedSlots: { customRender: "actions" }, //
                    },
                ]
            },
            initActionsType() {
            let _this = this;
            let obj = {
                type: "seal",
                isShow: true,
                fns: {
                dropList: [
                    {
                    granted: this.isGranted('Pages.TrainPermissions.Edit'),
                    name: this.l("Edit"),
                    icon: "edit",
                    fn: (data) => {
                       _this.createOrEdit(data.id);
                    },
                    },
                    {
                        granted: this.isGranted('Pages.TrainPermissions.StudyContext'),
                        name: this.l('StudyContent'),
                        icon: "profile",
                        fn: (data) => {
                            ModalHelper.create(
                                StudyList,
                                { id: data.id },
                                {
                                    width: "850px",
                                }
                            ).subscribe((res) => {
                                if (res) {
                                    _this.refreshGoFirstPage();
                                }
                            });
                        },
                    },
                    {
                        granted: this.isGranted('Pages.TrainPermissions.Enable'),
                        name: this.l("Disabled"),
                        icon: "bell",
                        fn: (data) => {
                            abp.message.confirm(_this.l("IsChangeStatus"), _this.l("HintInformation"), res => {
                                if (res) {
                                    _this.spinning = true;
                                    let options = {
                                        params: { guid: data.id },
                                        method: "GET",
                                        url: _this.$apiUrl + '/api/services/app/Train/ChangeStatus',
                                        headers: {
                                            "Content-Type": "application/json-patch+json",
                                        }
                                    }
                                    _this.$api.request(options).then(res => {
                                        if (res.status == 200) {
                                            abp.message.success(_this.l("Success"));
                                            _this.getData();
                                        } else {
                                            abp.message.warn(res.statusText);
                                        }
                                    }).catch(e => {
                                        console.error(e);
                                        abp.message.warn(_this.l("DefaultErrorMessage"));
                                    }).finally(() => {
                                        _this.spinning = true;
                                    });
                                }
                            });
                        },
                    },
                   
                ],
                },
            };
            this.actionsType = obj;
            },
            _initActionsType() {
                let options = [
                    {
                        granted: this.isGranted('Pages.TrainPermissions.Edit'),
                        key: 'edit',
                        name: this.l("Edit"),
                        icon: 'edit',
                        click: data => {
                            this.createOrEdit(data.id);
                        }
                    },
                    {
                        granted: this.isGranted('Pages.TrainPermissions.StudyContext'),
                        key: 'study',
                        name: this.l("StudyContent"),
                        icon: 'copy',
                        click: data => {
                            ModalHelper.create(
                                StudyList,
                                { id: data.id },
                                {
                                    width: "850px",
                                }
                            ).subscribe((res) => {
                                if (res) {
                                    this.refreshGoFirstPage();
                                }
                            });
                        }
                    },
                    {
                        granted: this.isGranted('Pages.TrainPermissions.Enable'),
                        key: 'disabled',
                        name: this.l("Disabled"),
                        icon: 'bell',
                        click: data => {
                            abp.message.confirm(this.l("IsChangeStatus"), this.l("HintInformation"), res => {
                                if (res) {
                                    this.spinning = true;
                                    let options = {
                                        params: { guid: data.id },
                                        method: "GET",
                                        url: this.$apiUrl + '/api/services/app/Train/ChangeStatus',
                                        headers: {
                                            "Content-Type": "application/json-patch+json",
                                        }
                                    }
                                    this.$api.request(options).then(res => {
                                        if (res.status == 200) {
                                            abp.message.success(this.l("Success"));
                                            this.getData();
                                        } else {
                                            abp.message.warn(res.statusText);
                                        }
                                    }).catch(e => {
                                        console.error(e);
                                        abp.message.warn(this.l("DefaultErrorMessage"));
                                    }).finally(() => {
                                        this.spinning = true;
                                    });
                                }
                            });
                        }
                    }
                ];
                this.ActionList = [...options];
            },
            // 清除条件并刷新
            clearFilterAndRefresh() {
                this.SearchDto = {
                    sceneName: '',
                };
                this.request = { sorting: "", maxResultCount: 50, skipCount: 0 };
                this.filterText = "";
                this.getData();
            },
            // 分页
            updatePageChange(newV) {
            let { page, pageSize } = newV;
            this.pageNumber = page;
            this.request.skipCount = (page - 1) * this.request.maxResultCount;
            this.getData();
            },
            updateShowSizeChange(newV) {
            let { current, size } = newV;
            this.pageNumber = 1;
            this.request.maxResultCount = size;
            this.getData();
            },
            showTotalFun() {
                const res = this.l(
                    "GridFooterDisplayText",
                    this.pageNumber,
                    this.totalPages,
                    this.totalItems,
                    this.pagerange[0],
                    this.pagerange[1]
                );
                return res;
            },

            emitItem(item, scope) {
                if (item.click && typeof item.click == "function") {
                    item.click(scope);
                }
            },
            search() {
                this.pageNumber = 1
                this.pagination.current = 1
                this.getData()
            },
            getData() {
                this.spinning = true;
                let url = this.$apiUrl + "/api/services/app/Train/PageList";
                let options = {
                    // data: { sceneName: this.SearchDto.sceneName, pageSize: this.pagination.pageSize, pageIndex: this.pagination.current },
                    data: { sceneName: this.SearchDto.sceneName, pageSize: this.request.maxResultCount, pageIndex: this.pagination.current },
                    method: "POST",
                    url: url,
                    headers: {
                        "Content-Type": "application/json-patch+json",
                    },
                };
                this.$api
                    .request(options)
                    .then((response) => {
                        if (response.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            let res = response.data;
                            if (res.items && res.items.length > 0) {
                                this.tableData = res.items.map((item) => {
                                    return {
                                        id: item.guid,
                                        urls: item.guid,
                                        name: item.sceneName,
                                        description: item.introduce,
                                        trainTime: this.timeFormat(item.effectiveStartTime),
                                        endTrainTime: this.timeFormat(item.effectiveEndTime),
                                        testTime: this.timeFormat(item.testStartTime),
                                        endTestTime: this.timeFormat(item.testEndTime),
                                        // status: item.status,
                                        // isCheck: item.isRequiredCourse,
                                        createTime: item.createTime,
                                        template: item.trainSceneTemplates,
                                        testLengthTime: item.testLengthTime,
                                        studyNotifyUrl: item.studyNotifyUrl,
                                        testNotifyUrl: item.testNotifyUrl,
                                        verificationUrl: item.verificationUrl,
                                        singleText: this.getTitleText(item.trainSceneTemplates, 1),
                                        multText: this.getTitleText(item.trainSceneTemplates, 2),
                                        templateList: [
                                            {label: this.l("ExamLength") + '：', value: item.testLengthTime},
                                            {label: this.l("SingerTitle") + '：', value: this.getTitleText(item.trainSceneTemplates, 1)},
                                            {label: this.l("MultipleTitle") + '：', value: this.getTitleText(item.trainSceneTemplates, 2)},
                                        ],
                                        urlsList: [
                                            {label: this.l("StudyNotifyUrl") + '：', value: item.studyNotifyUrl},
                                            {label: this.l("ExamNotifyUrl") + '：', value: item.testNotifyUrl},
                                            {label: this.l("CheckUri") + '：', value: item.verificationUrl},
                                        ],
                                        status: item.status == 0 ? this.l("Invalid") : this.l("Effective"),
                                        statusColor: item.status == 0 ? 'red' : 'blue',

                                        isCheck: item.isRequiredCourse == true ? this.l("NeedClass") : this.l("NotNeedClass"),
                                        isCheckColor: item.isRequiredCourse == true ? 'red' : 'blue',
                               
                                    }
                                });

                                this.pagination.total = res.totalItems
  
                                this.totalItems = res.totalItems;
                                this.pagerange = [
                                    (this.pageNumber - 1) * this.request.maxResultCount + 1,
                                    this.pageNumber * this.request.maxResultCount,
                                ];
                                // this.totalPages = Math.ceil(
                                //     res.totalItems / this.request.maxResultCount
                                // );
                                this.totalPages = res.totalPages

                            }
                        }
                    })
                    .catch((e) => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    })
                    .finally(() => {
                        this.spinning = false;
                    });
            },
            createOrEdit(id) {
                ModalHelper.create(
                    CreateOrUpdate,
                    { id: id },
                    {
                        width: 800,
                    }
                ).subscribe((res) => {
                    if (res) {
                        this.refreshGoFirstPage();
                    }
                });
            },
            restCheckStatus() {
                this.selectedRowKeys = [];
            },
            tableChange(pagination, filters, sorter) {
                const pager = { ...this.pagination };
                pager.current = pagination.current;
                this.pagination = pager;
                this.getData();
            },
            timeFormat(time) {
                return time.toString().replace("T", " ");
            },
            getTitleText(template, type) {
                var rows = template.filter(e => e.questionType == type);
                if (rows && rows.length >= 1) {
                    return rows[0].num + '题' + (rows[0].num * rows[0].score) + '分';
                }
                return '';
            },
            refreshGoFirstPage() {
                this.SearchDto.sceneName = "";
                this.SearchDto.pageIndex = 1;
                this.getData();
            }
        },
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }
    .tbl-edit{
        display:block;
        margin-top:3px;
    }
</style>
