<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span v-if="id">{{ l("Edit") }}</span>
                <span v-if="!id">{{ l("Create") }}</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <a-row :gutter="24">
                <a-col span="12">
                    <!-- 标题 -->
                    <a-form-item :label="l('Title')">
                        <a-input :placeholder="l('Title')" v-decorator="['Title', {rules: [ { required: true,message: l('ThisFieldIsRequired') }]}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!-- 排序 -->
                    <a-form-item :label="l('Sort')">
                        <a-input-number :placeholder="l('Sort')" style="width:99%;" :step="1" :min="0" v-decorator="['Sort', {rules:[]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="24">
                    <!--内容-->
                    <a-form-item :label="l('WebSiteNoticeContent')">
                        <div id="StudyContent"></div>
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>

<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import E from 'editor4kg';

    export default {
        mixins: [ModalComponentBase],
        name: "create-or-edit-project",
        data() {
            return {
                // 获取到的数据
                entity: {},
                spinning: false,
                singleNum: 0,
                singleTotal: 0,
                multNum: 0,
                multTotal: 0,
                IsRequiredCourse: false,
                Editor: null
            };
        },
        components: {},
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "saveStudy" });
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        mounted() {
            this.getData();
            const editor = new E("#StudyContent");
            editor.config.menus = ['head',
                'bold',
                'fontSize',
                'fontName',
                'italic',
                'underline',
                'strikeThrough',
                'indent',
                'lineHeight',
                'foreColor',
                'backColor',
                'link',
                'list',
                'justify',
                'quote',
                'emoticon',
                'table',
                'code',
                'splitLine',
                'undo',
                'redo',
                'selectValue']
            editor.create();
            this.Editor = editor;
        },
        methods: {
            /**
             * 获取数据
             */
            getData() {
                if (this.id && this.id.toString().indexOf('-') > 0) {
                    this.spinning = true;
                    let options = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json-patch+json",
                        },
                        url: this.$apiUrl + '/api/services/app/Train/GetStudyContentDetail',
                        params: { guid: this.id }
                    };
                    this.$api.request(options).then(res => {
                        if (res.status == 200) {
                            let values = res.data;
                            this.form.setFieldsValue({
                                Title: values.title,
                                Sort: values.sort
                            });
                            this.Editor.txt.html(decodeURIComponent(values.content));
                        } else {
                            abp.message.warn(res.statusText);
                        }
                    }).catch(e => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    }).finally(() => {
                        this.spinning = false;
                    })
                }
            },
            /**
             * 提交表单
             */
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        let content = this.Editor.txt.html();
                        if (!content || content == '') {
                            abp.message.warn(this.l("StudyContentRequired"));
                            return false;
                        }
                        let data = {
                            "title": values.Title,
                            "sort": values.Sort,
                            "contentType": 2,
                            "trainSceneGuid": this.listid,
                            "content": encodeURIComponent(content),
                            "guid": null
                        };
                        let options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json-patch+json",
                            },
                            url: '',
                            data: null
                        }
                        if (this.id && this.id.toString().indexOf('-') > 0) {
                            //修改
                            options.url = this.$apiUrl + '/api/services/app/Train/UpdateTrainStudyContent';
                            data.guid = this.id;
                            options.data = data;
                        } else {
                            //新增
                            delete data.guid;
                            options.data = data;
                            options.url = this.$apiUrl + '/api/services/app/Train/CreateTrainStudyContent';
                        }
                        this.spinning = true;
                        this.$api.request(options).then(res => {
                            if (res.status == 200) {
                                abp.message.success("操作成功");
                                this.success(true);
                            } else {
                                abp.message.warn(res.statusText);
                            }
                        }).catch(e => {
                            console.error(e);
                            abp.message.warn(this.l("DefaultErrorMessage"));
                        }).finally(() => {
                            this.spinning = false;
                        });
                    }
                });
            }
        }
    };
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>
