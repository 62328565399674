<template>
    <a-spin :spinning="spinning">
        <div class="modal-header">
            <div class="modal-title">
                <span v-if="id">{{ l("Edit") }}</span>
                <span v-if="!id">{{ l("Create") }}</span>
            </div>
        </div>
        <a-form :form="form" @submit="handleSubmit" layout="horizontal">
            <a-row :gutter="24">
                <a-col span="12">
                    <!-- 培训名称 -->
                    <a-form-item :label="l('TrainName')">
                        <a-input :placeholder="l('TrainName')" v-decorator="['TrainName', {rules: [ { required: true,message: l('ThisFieldIsRequired') }]}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!-- 有效时间 -->
                    <a-form-item :label="l('TrainHeaderEffectiveTime')">
                        <a-range-picker :placeholder="[l('StartTime'), l('EndTime')]"
                                        valueFormat="YYYY-MM-DD HH:mm:ss" show-time
                                        v-decorator="[ 'EffectiveTime', { rules: [{ required: true, message: l('ThisFieldIsRequired')}]} ]">
                            <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date">
                                    {{ current.date() }}
                                </div>
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--学习通知-->
                    <a-form-item :label="l('StudyNotifyUrl')">
                        <a-input :placeholder="l('StudyNotifyUrl')" v-decorator="['StudyNotifyUrl', {rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--考试通知-->
                    <a-form-item :label="l('ExamNotifyUrl')">
                        <a-input :placeholder="l('ExamNotifyUrl')" v-decorator="['ExamNotifyUrl', {rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--校验url-->
                    <a-form-item :label="l('CheckUri')">
                        <a-input :placeholder="l('CheckUri')" v-decorator="['CheckUri', {rules: []}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--可考试次数-->
                    <a-form-item :label="l('CanExamNum')">
                        <a-input-number :placeholder="l('CanExamNum')" style="width:100%;" :step="1" :min="1" v-decorator="['CanExamNum', {rules: [ { required: true,message: l('ThisFieldIsRequired') }]}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--培训介绍-->
                    <a-form-item :label="l('TrainDescription')">
                        <a-textarea :placeholder="l('TrainDescription')" v-decorator="['TrainDescription',{rules: [{required:true,message:l('ThisFieldIsRequired')}]}]" :rows="4" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--是否必修-->
                    <a-form-item :label="l('TrainHeaderIsCheck')">
                        <a-switch :checked-children="l('Yes')" v-model="IsRequiredCourse" :un-checked-children="l('No')" v-decorator="['IsCheck',{rules: []}]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--通过考试分数-->
                    <a-form-item :label="l('PassNum')">
                        <a-input-number step="1" :min="1" style="width:100%;" v-decorator="['PassNum',{rules: [{required:true,message:l('ThisFieldIsRequired')}]}]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--考试时长(分钟)-->
                    <a-form-item :label="l('ExamLength')">
                        <a-input-number step="1" :min="1" style="width:100%;" :placeholder="l('ExamLength')" v-decorator="['ExamLength',{rules: [{required:true,message:l('ThisFieldIsRequired')}]}]" :rows="4" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--考试时间-->
                    <a-form-item :label="l('ExamTime')">
                        <a-range-picker :placeholder="[l('StartTime'), l('EndTime')]"
                                        valueFormat="YYYY-MM-DD HH:mm:ss" show-time
                                        v-decorator="[ 'ExamTime', { rules: [{ required: true, message: l('ThisFieldIsRequired')}]} ]">
                            <template slot="dateRender" slot-scope="current">
                                <div class="ant-calendar-date">
                                    {{ current.date() }}
                                </div>
                            </template>
                        </a-range-picker>
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--考试模板-->
                    <a-form-item :label="l('CurrentTotalNum')">
                        <a-input-number readonly style="width: 100%;" v-decorator="[ 'CurrentTotalNum', { rules: []} ]" />
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row :gutter="24">
                <a-col span="12">
                    <!--单选题-->
                    <a-form-item :label="l('SingerTitle')">
                        {{l("PerTitleNum")}}:&nbsp;<a-input-number :step="1" :min="1" style="width:80px;" @change="SPNumberChange" v-decorator="[ 'SinglePerTitleNum', { rules: []} ]" />
                        <span style="width:20px;">&nbsp;</span>
                        {{l("TotalTitle")}}:&nbsp;<a-input-number :step="1" :min="1" style="width:80px;" @change="STNumberChange" v-decorator="[ 'SingleTotalTitle', { rules: []} ]" />
                    </a-form-item>
                </a-col>
                <a-col span="12">
                    <!--多选题-->
                    <a-form-item :label="l('MultipleTitle')">
                        {{l("PerTitleNum")}}:&nbsp;<a-input-number :step="1" :min="1" style="width:80px;" @change="MPNumberChange" v-decorator="[ 'MultPerTitleNum', { rules: []} ]" />
                        <span style="width:20px;">&nbsp;</span>
                        {{l("TotalTitle")}}:&nbsp;<a-input-number :step="1" :min="1" style="width:80px;" @change="MTNumberChange" v-decorator="[ 'MultTotalTitle', { rules: []} ]" />
                    </a-form-item>
                </a-col>
            </a-row>

            <a-row :gutter="24">
                <a-col span="24">
                    <a-form-item class="btn--container" style="text-align: center">
                        <a-button type="button" @click="close()">
                            {{ l("Cancel") }}
                        </a-button>
                        <a-button type="primary" html-type="submit">
                            {{ l("Save") }}
                        </a-button>
                    </a-form-item>
                </a-col>
            </a-row>
        </a-form>
    </a-spin>
</template>

<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";

    export default {
        mixins: [ModalComponentBase],
        name: "create-or-edit-project",
        data() {
            return {
                // 获取到的数据
                entity: {},
                spinning: false,
                singleNum: 0,
                singleTotal: 0,
                multNum: 0,
                multTotal: 0,
                IsRequiredCourse: false
            };
        },
        components: {},
        beforeCreate() {
            this.form = this.$form.createForm(this, { name: "trainsave" });
        },
        created() {
            this.fullData(); // 模态框必须,填充数据到data字段
        },
        mounted() {
            this.getData();
        },
        methods: {
            /**
             * 获取数据
             */
            getData() {
                if (this.id && this.id.toString().indexOf('-') > 0) {
                    this.spinning = true;
                    let options = {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json-patch+json",
                        },
                        url: this.$apiUrl + '/api/services/app/Train/GetById',
                        params: { guid: this.id }
                    };
                    this.$api.request(options).then(res => {
                        if (res.status == 200) {
                            let values = res.data;
                            this.form.setFieldsValue({
                                TrainName: values.sceneName,
                                TrainDescription: values.introduce,
                                EffectiveTime: [values.effectiveStartTime, values.effectiveEndTime],
                                ExamTime: [values.testStartTime, values.testEndTime],
                                ExamLength: values.testLengthTime,
                                CanExamNum: values.testCount,
                                PassNum: values.passScore,
                                IsCheck: values.isRequiredCourse,
                                SinglePerTitleNum: values.trainSceneTemplates.filter(e => e.questionType == 1)[0].score,
                                SingleTotalTitle: values.trainSceneTemplates.filter(e => e.questionType == 1)[0].num,
                                MultPerTitleNum: values.trainSceneTemplates.filter(e => e.questionType == 2)[0].score,
                                MultTotalTitle: values.trainSceneTemplates.filter(e => e.questionType == 2)[0].num,
                                StudyNotifyUrl: values.studyNotifyUrl,
                                ExamNotifyUrl: values.testNotifyUrl,
                                CheckUri: values.verificationUrl,
                                CurrentTotalNum: this.getTotal(values.trainSceneTemplates)
                            });
                            this.IsRequiredCourse = values.isRequiredCourse;
                        } else {
                            abp.message.warn(res.statusText);
                        }
                    }).catch(e => {
                        console.error(e);
                        abp.message.warn(this.l("DefaultErrorMessage"));
                    }).finally(() => {
                        this.spinning = false;
                    })
                }
            },
            /**
             * 提交表单
             */
            handleSubmit(e) {
                e.preventDefault();
                this.form.validateFieldsAndScroll((err, values) => {
                    if (!err) {
                        let data = {
                            "sceneName": values.TrainName,
                            "introduce": values.TrainDescription,
                            "effectiveStartTime": values.EffectiveTime[0],
                            "effectiveEndTime": values.EffectiveTime[1],
                            "testStartTime": values.ExamTime[0],
                            "testEndTime": values.ExamTime[1],
                            "testLengthTime": values.ExamLength,
                            "testCount": values.CanExamNum,
                            "passScore": values.PassNum,
                            "isRequiredCourse": this.IsRequiredCourse,
                            "trainSceneTemplates": [
                                {
                                    "questionType": 1,
                                    "score": values.SinglePerTitleNum,
                                    "num": values.SingleTotalTitle,
                                    "nowNum": 0
                                },
                                {
                                    "questionType": 2,
                                    "score": values.MultPerTitleNum,
                                    "num": values.MultTotalTitle,
                                    "nowNum": 0
                                }
                            ],
                            "studyNotifyUrl": values.StudyNotifyUrl,
                            "testNotifyUrl": values.ExamNotifyUrl,
                            "verificationUrl": values.CheckUri,
                            "guid": null
                        };
                        let options = {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json-patch+json",
                            },
                            url: '',
                            data: null
                        }
                        if (this.id && this.id.toString().indexOf('-') > 0) {
                            //修改
                            options.url = this.$apiUrl + '/api/services/app/Train/UpdateTrainScene';
                            data.guid = this.id;
                            options.data = data;
                        } else {
                            //新增
                            delete data.guid;
                            options.data = data;
                            options.url = this.$apiUrl + '/api/services/app/Train/CreateTrainScene';
                        }
                        this.spinning = true;
                        this.$api.request(options).then(res => {
                            if (res.status == 200) {
                                abp.message.success(this.l("SuccessfullyOperation"));
                                this.success(true);
                            } else {
                                abp.message.warn(res.statusText);
                            }
                        }).catch(e => {
                            console.error(e);
                            abp.message.warn(this.l("DefaultErrorMessage"));
                        }).finally(() => {
                            this.spinning = false;
                        });
                    }
                });
            },
            /*
             * 计算总分
             */
            STNumberChange(val) {
                this.singleTotal = val;
                this.NumberChange();
            },
            SPNumberChange(val) {
                this.singleNum = val;
                this.NumberChange();
            },
            MPNumberChange(val) {
                this.multNum = val;
                this.NumberChange();
            },
            MTNumberChange(val) {
                this.multTotal = val;
                this.NumberChange();
            },
            NumberChange() {
                let total = this.singleNum * this.singleTotal + this.multNum * this.multTotal;
                this.form.setFieldsValue({
                    CurrentTotalNum: total
                });
            },
            getTotal(template) {
                let total = 0;
                for (let i = 0; i < template.length; i++) {
                    let item = template[i];
                    total += item.num * item.score;
                }
                return total;
            }
        },
    };
</script>

<style lang="less" scoped>
    .btn--container .ant-form-item-children {
        display: block;
        text-align: center;
    }

    .pleaseSelect-text {
        font-size: 14px;
        padding: 0 14px;
        text-align: center;
        color: rgba(0, 0, 0, 0.65);
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }
</style>
