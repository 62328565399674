<template>
    <a-spin :spinning="spinning">
        <!-- <page-header :title="l('ProjectUserManager')"></page-header> -->
        <a-card :bordered="false" style="height:700px;max-height:700px;overflow-y:auto;">
            <a-row :gutter="24" type="flex">
                <!--<a-col class="gutter-row" :span="6">
                    <a-input v-model="SearchDto.Name"
                             :placeholder="l('CourseCategoryName')" />
                </a-col>-->
                <!--<a-col class="gutter-row" :span="8" align="center">
                    <a-button type="primary" @click="getData">
                        {{ l("Search") }}
                    </a-button>
                    <a-button @click="refreshGoFirstPage"> {{ l("Reset") }}</a-button>
                </a-col>-->

                <a-col span="16" align="left">
                    <a-button typeof="success" @click="getData">{{l("Refresh")}}</a-button>
                    <a-button type="primary" @click="createOrEdit">{{l("Create")}}</a-button>
                </a-col>
            </a-row>


            <a-table :data-source="tableData">
                <a-table-column-group>
                    <a-table-column :key="1" align="center" :title="l('WebSiteNoticeTitle')" data-index="title" />
                    <!--<a-table-column :key="2" align="center" :title="l('WebSiteNoticeContent')" data-index="content" />-->
                    <a-table-column :key="3" :title="l('SysFileContentType')" data-index="contentType">
                        <template slot-scope="text, scope">
                            <a-tag :color="text == 1 ? 'cyan' : 'red'">{{text == 1 ? '视频' : '文本'}}</a-tag>
                        </template>
                    </a-table-column>
                    <a-table-column :key="4" :title="l('Action')" data-index="guid">
                        <template slot-scope="text, scope">
                            <a class="tbl-edit" v-for="item in ActionList" :key="item.key" @click="emitItem(item, scope)">
                                <span><a-icon :type="item.icon" />{{item.name}}&nbsp;&nbsp;</span>
                            </a>
                        </template>
                    </a-table-column>
                </a-table-column-group>
            </a-table>
        </a-card>

    </a-spin>
</template>
<script>
    import { ModalComponentBase } from "@/shared/component-base";
    import { ModalHelper } from "@/shared/helpers";
    import axios from "axios";
    import CreateOrUpdate from "./addstudy.vue";
    import moment from "moment";

    export default {
        mixins: [ModalComponentBase],
        name: "projectmodule",
        data: function () {
            return {
                spinning: false,
                SearchDto: {
                    Name: ""
                },
                ActionList: [],
                tableData: [{ id: '123', name: '测试', content: "1234", studyType: 1 }],
            };
        },
        methods: {
            initActionsType() {
                let options = [
                    {
                        key: 'edit',
                        name: '修改',
                        icon: 'edit',
                        click: data => {
                            this.createOrEdit(data.guid);
                        }
                    },
                    {
                        key: 'delete',
                        name: '删除',
                        icon: 'delete',
                        click: data => {
                            this.deleteItem(data.guid);
                        }
                    }
                ];
                this.ActionList = [...options];
            },
            emitItem(item, scope) {
                if (item.click && typeof item.click == "function") {
                    item.click(scope);
                }
            },
            getData() {
                if (this.id && this.id.toString().indexOf('-') > 0) {
                    this.spinning = true;
                    let options = {
                        url: this.$apiUrl + '/api/services/app/Train/GetStudyContentList',
                        method: 'POST',
                        data: {
                            trainSceneGuid: this.id
                        },
                        headers: {
                            "Content-Type": "application/json-patch+json",
                        }
                    };
                    this.$api.request(options).then(res => {
                        if (res.status == 200) {
                            this.tableData.splice(0, this.tableData.length);
                            let data = res.data;
                            if (data && data.length > 0) {
                                this.tableData = [...data];
                            }
                        }
                    }).catch(e => {
                        console.error(e);
                        abp.message.error(this.l("DefaultErrorMessage"));
                    }).finally(() => {
                        this.spinning = false;
                    });
                }
            },
            deleteItem(id) {
                abp.message.confirm(this.l("IsChangeStatus"), this.l("HintInformation"), res => {
                    this.spinning = true;
                    this.$api.request({
                        url: this.$apiUrl + '/api/services/app/Train/DeleteStudyContent',
                        method: 'POST',
                        data: {
                            guid: id
                        },
                        headers: {
                            "Content-Type": "application/json-patch+json",
                        }
                    }).then(res => {
                        if (res.status == 200) {
                            abp.message.success(this.l("SuccessfullyOperation"));
                            this.getData();
                        } else {
                            abp.message.warn(res.statusText);
                        }
                    }).catch(e => {
                        console.error(e);
                        abp.message.error(this.l("DefaultErrorMessage"));
                    }).finally(() => {
                        this.spinning = false;
                    })
                });
            },
            createOrEdit(id) {
                if (!id || id.toString().indexOf('-') <= 0) {
                    id = '';
                }
                ModalHelper.create(CreateOrUpdate, { id: id, listid: this.id }, { width: "500px" }).subscribe(res => {
                    if (res) {
                        this.getData();
                    }
                });
            }
        },
        created() {
            this.fullData();
        },
        mounted() {
            this.getData();
            this.initActionsType();
        }
    };
</script>
<style lang="less" scoped>
    .btn--container {
        margin-top: 20px;
    }

    .btn--footer {
        margin-bottom: 20px;
    }

    .tbl-edit {
        display: block;
        margin-top: 3px;
    }
</style>
